import React, { useRef } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import '../../css/main.css';

const IconInput = React.forwardRef(({ placeholder, icon, onChange, name, size, type, isWarningVisible, autoComplete }, ref) => {

    const inputStyle = {
        border: isWarningVisible ? '3px solid red' : '1px solid black',
    };

    return (
        <InputGroup style={ inputStyle } className="InputGroupContainerOverride">
            <InputGroupText className="InputGroupIconContainerOverride">
                {icon}
            </InputGroupText>
            <Input
                className="InputGroupInputOverride"
                placeholder={placeholder}
                innerRef={ref}
                onChange={onChange}
                name={name}
                bsSize={size}
                type={type}
                autoComplete={autoComplete}
            />
        </InputGroup>

    )
});

export default IconInput
