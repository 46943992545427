import React, { Component, useContext } from 'react';
import axios from 'axios';
import { Container, Button, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import "../css/main.css"
import IconInput from '../components/Shared/IconInput'
import { Search } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { ProdView } from '../components/ProdView'
import { ArrowLeft } from 'react-bootstrap-icons';

export class Products extends Component {
    static displayName = Products.name;
    static bearerToken = localStorage.getItem('bearerToken');
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            loading: true,
            Categories: [],
            Content: [],
            filterBy: 0,
            filterList: [],
            ShowProductList: true,
            SelectedProdID: 0,
            imageDirectory: "",
            imageDataList:[]
        };
    }

    componentDidMount() {
        this.setState({ loading: true })
        //this.GetImageData();
        this.getProductsList();
        this.getCategories();
        this.GetImageDirectory();
      
    }


    async GetImageData() {

        await axios.get("/Website/GetImageData", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                console.log(response.data)
                var list = response.data.map((item) => ({
                    fileName: item.fileName,
                    fileData: item.fileData,
                }));

                this.setState({ imageDataList: list });
               
            })
            .catch(error => {
                console.error(error);
            });
    }

    async GetImageDirectory() {

        await axios.get("/Website/GetImageDirectory", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                console.log(response.data)
                this.setState({ imageDirectory: response.data });
                this.setState({ loading: false })
            })
            .catch(error => {
                console.error(error);
            });
    }

    async getProductsList() {
        await axios.get('/Website/getProducts', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('bearerToken') }`
            }
        })
            .then(response => {
                this.setState({ products: response.data });
                this.generateProductsForm(response.data);
            })
            .catch(error => {
                // Handle the error
                console.error(error);
            });
    }


    async generateProductsForm(list) {
        this.setState({ Content: list })
    }

    async getCategories() {
        await axios.get('/Website/GetCategories', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('bearerToken') }`
            }
        })
            .then(response => {
                this.setState({ Categories: response.data })
                
            })
            .catch(error => {
                // Handle the error
                console.error(error);
            });
    }

    handleCategoryChange = (e) => {
        var catID = parseInt(e.target.value)
        console.log(catID)
        if (catID !== 0) {
            if (this.state.filterList.length === 0) {
                const filteredList = this.state.products.filter(
                    item => item.caT_ID.includes(catID)
                );
                this.setState({ Content: filteredList });
            } else {
                const filteredList = this.state.filterList.filter(
                    item => item.caT_ID.includes(catID)
                );
                this.setState({ Content: filteredList });
            }   
        }
        else {
            if (this.state.filterList.length === 0) {
                this.setState({ Content: this.state.products })
            } else {
                this.setState({ Content: this.state.filterList });
            }
        }

        this.setState({ filterBy: catID })

    };

    SearchProducts(text) {
        const searchParam = ["proD_NAME"];
        var result = [];

        if (this.state.filterBy === 0) {
            result = this.state.products.filter((item) => {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(text.toLowerCase()) > -1
                    );
                });
            });

        } else {
            var textFiltered = this.state.products.filter((item) => {

                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(text.toLowerCase()) > -1
                    );
                });
            });

            result = textFiltered.filter(
                item => item.caT_ID.includes(this.state.filterBy)
            );
            console.log(this.state.filterBy)
            console.log(result)
        }
        this.setState({ filterList: result})
        this.setState({ Content: result })

    }

    productSelected(prod) {
        this.setState({ SelectedProdID: prod })
        this.setState({ ShowProductList: false })
    }

    GetDeviceImage(imagePath) {
        console.log(imagePath)
        var imageData = this.state.imageDataList.find(
            (x) => x.fileName === imagePath
        );
        console.log(imageData)
        if (imageData) {
            var data = imageData.fileData
            console.log()
            return data;
        } else {
            return null;
        }
    }

    render() {

        let renderContent;
        if (this.state.loading === true) {
            renderContent = (<Container style={{ textAlign: 'center', paddingTop: '10%' }}><span className="loader"></span></Container>)
        } else if (this.state.ShowProductList === false) {
            renderContent = (
                <div>
                    <Button
                        color="link"
                        className="back-button"
                        onClick={() => {
                            this.setState({ SelectedProdID: null })
                            this.setState({ ShowProductList: true })
                        }}
                    >
                        <ArrowLeft className="back-icon" size={20} color="black" />
                        <span className="back-text">Back to Products</span>
                    </Button>
                    <ProdView prod={this.state.SelectedProdID} />
                </div>);
        } else {
            renderContent = (
                <Row style={{ marginTop: "20px" }}>
                    <Col sm="3" className="category-col">
                        {/* Filter Header */}

                        <h4 className="filter-header">Filters</h4>

                        {/* Category Selector */}
                        <div className="category-list">
                            <div className="category-option">
                                <input
                                    type="radio"
                                    id="allCategories"
                                    name="category"
                                    value="0"
                                    checked={this.state.filterBy === 0}
                                    onChange={this.handleCategoryChange}
                                />
                                <label htmlFor="allCategories">All Products</label>
                            </div>
                            {this.state.Categories.map((category) => (
                                <div className="category-option" key={category.caT_ID}>
                                    <input
                                        type="radio"
                                        id={category.caT_ID}
                                        name="category"
                                        value={category.caT_ID}
                                        checked={this.state.filterBy === category.caT_ID}
                                        onChange={(e) => { this.handleCategoryChange(e) }}
                                    />
                                    <label htmlFor={category.caT_ID}>{category.caT_NAME}</label>
                                </div>
                            ))}
                            <IconInput placeholder="Search" icon={<Search />} name="NavSearchBar" size="sm" type="Text" autoComplete="search" onChange={(e) => this.SearchProducts(e.target.value)} />
                        </div>

                    </Col>
                    <Col sm="9" className="centralize-cards">
                        <Row>
                            {this.state.Content.map((product, index) => (
                                <Col sm="6" md="4" lg="3" key={index} className="card-col">
                                    <Card className="card" onClick={() => { this.productSelected(product) } }>
                                        {product.imG_PATH && (
                                            <div className="image-container">
                                                <CardImg
                                                    className="card-image"
                                                    src={this.state.imageDirectory + '/' + product.imG_PATH}
                                                    
                                                />
                                            </div>
                                        )}
                                        <CardBody>
                                            <CardTitle>{product.proD_NAME}</CardTitle>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>

            );
        }

        return (renderContent);
    }

    async renderCardImage(imagePath) {
        const imageData = await this.fetchImageData(imagePath);

        if (imageData) {
            return (
                <CardImg
                    className="card-image"
                    src={`data:image/jpeg;base64,${imageData.fileData}`}
                />
            );
        } else {
            return null; // or any fallback component if necessary
        }
    }

    async fetchImageData(imagePath) {
        const imageData = this.state.imageDataList.find(
            (x) => x.fileName === imagePath
        );

        return imageData || null;
    }
    }

  

