import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Modal, ModalBody, } from 'reactstrap';
import { CardList, FileText, Grid3x3GapFill, FileEarmarkText, FileEarmarkPdf, Download } from 'react-bootstrap-icons';
import "../css/main.css"

export class ProdView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            isLoading: false,
            error: null,
            Content: null,
            selectedButton: 'product',
            showDescription: true,
            features: null,
            Documents: null,
            Gallery: null,
            isOpen: false,
            selectedImage: null,
            currentIndex: 0,
            imageDirectory: ""
        };
    }

    async componentDidMount() {
        this.GetImageDirectory();
        this.setState({ isLoading: true })
        this.setState({ product: this.props.prod })
        await this.GetProductFeatures();
        await this.GetProductDocumentation();
        await this.GetGalleryFiles();
        await this.renderProduct();
       
        this.setState({ isLoading: false })
    }

    async GetImageDirectory() {

        await axios.get("/Website/GetImageDirectory", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                console.log(response.data)
                this.setState({ imageDirectory: response.data });
            })
            .catch(error => {
                console.error(error);
            });
    }

    async GetGalleryFiles() {

        var prodID = this.props.prod.proD_ID;
        await axios.post("/Website/GetGalleryImagesByPath", prodID, {
            headers: {
                'Content-Type': 'application/json',
                 Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                this.setState({ Gallery: response.data });
            })
            .catch(error => {
                console.error(error);
                // Handle any errors that occurred during the request
            });
    }

    async GetProductFeatures() {

        var prodID = this.props.prod.proD_ID;
        await axios.post("/Website/GetDescriptionByProdID", prodID, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                console.log(response.data);
                var arr = JSON.parse(response.data.desC_JSON)
                this.setState({ features: arr.list });
            })
            .catch(error => {
                console.error(error);
                // Handle any errors that occurred during the request
            });
    }
    async GetProductDocumentation() {
        var prodID = this.props.prod.proD_ID;
        await  axios.post("/Website/GetDocumentsbyProdID", prodID, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                console.log(response.data);
                this.setState({ Documents: response.data });
            })
            .catch(error => {
                console.error(error);
                // Handle any errors that occurred during the request
            });
    }

    renderFeatures() {
        console.log(this.state.Documents)
        var features = (<>
            <Col sm={6}>
                <div className="product-description-container fade-in">
                    <div>
                        <h3>Features:</h3>
                        <ul className="bullet-list">
                            {this.state.features.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Col>
            <Col  className="text-center">
                      
                        <div className="image-container-ProdView">
                            <img
                            src={this.state.imageDirectory + '/'+ this.props.prod.imG_PATH}
                                alt="Product"
                                className="product-image-ProdView"
                            />
                        </div>
                    </Col>
                   
        </>)

            

        this.setState({ Content: features })
    }

    renderDocuments() {
        const style = {
            fontSize: '20px',
            marginTop: '10%'
        }
        var documents = (<>
            <Col sm={6} style={style} >
            <h3>Documents</h3>
                {this.state.Documents.map((document, index) => {
                    let icon = <FileEarmarkText />;
                    if (document.doC_PATH.includes("pdf")) {
                        icon = <FileEarmarkPdf />;
                    } else if (document.doC_PATH.includes("exe")) {
                        return (
                            <div key={index}>
                                <a
                                    href={this.state.imageDirectory+'/'+ document.doC_PATH}
                                    download
                                    style={{ color: 'black', display: 'block', marginBottom: '10px' }}
                                >
                                    <Download />   {document.doC_NAME}
                                    
                                </a>
                            </div>
                        );
                    }
                    return (
                        <div key={index}>
                            <a
                                href={this.state.imageDirectory+'/'+ document.doC_PATH}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'black', display: 'block', marginBottom: '10px' }}
                            >
                                {icon} {document.doC_NAME}
                            </a>
                        </div>
                    );
                })}
            </Col>
            <Col className="text-center">
                <div className="image-container-ProdView">
                    <img
                        src={this.state.imageDirectory + '/' + this.props.prod.imG_PATH}
                        alt="Product"
                        className="product-image-ProdView"
                    />
                </div>
            </Col>
        </>)



        this.setState({ Content: documents })
    }

    renderProduct() {
        var Product = (<> <Col sm={6} className="text-center">
            <div className="image-container-ProdView">
                <img
                    src={this.state.imageDirectory + '/' + this.props.prod.imG_PATH}
                    alt="Product"
                    className="product-image-ProdView"
                />
            </div>
        </Col>
            <Col >
                <div className="product-description-container-floating fade-in text-center">
                    <div className="product-description">{this.props.prod.proD_DESC}</div>
                </div>
            </Col></>)

        this.setState({ Content: Product })
    }

    renderGallery() {
        console.log(this.state.Gallery)
        var Gallery = (<>
            <Col sm={6} className="text-center">
                <h3>Gallery</h3>
                <div className="image-container-ProdView-gallery image-grid">
                    {this.state.Gallery.map((image, index) => (
                        <div key={index} className="image-item-gallery-prod" onClick={() => this.toggleModal(image.imG_PATH, index)}>
                            <img
                                src={this.state.imageDirectory + '/'+image.imG_PATH}
                                alt="Product"
                                className="product-image-ProdView-gallery"
                               
                            />
                        </div>
                    ))}
                </div>
            </Col>
            <Col >

            </Col>
        </>)

        this.setState({ Content: Gallery })
    }

    handleButtonClick = (buttonName) => {
        this.setState({ selectedButton: buttonName });

        if (buttonName === "features") {
            this.renderFeatures()
        } else if (buttonName === "product") {
            this.renderProduct()
        } else if (buttonName === "documents") {
            this.renderDocuments()
        } else if (buttonName === "gallery") {
            this.renderGallery()
        }
    };


    toggleModal = (image, index) => {
        console.log(index)
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
            selectedImage: image,
            currentIndex: index
        }));
    };

    closeModal = () => {
        this.setState({
            isOpen: false,
            selectedImage: null,
            currentIndex: 0
        });
    };

    goToPrevious = () => {
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex - 1
        }));
    };

    goToNext = () => {
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex + 1
        }));
    };


    render() {
        const { selectedButton, isOpen, selectedImage } = this.state;
        return (
            <Container fluid>
                <h2 className="justify-content-center text-center">{this.props.prod.proD_NAME}</h2>
                <Row className="justify-content-center">
                    <Col>
                        <div className="button-section d-flex align-items-center justify-content-center" style={{ marginTop: '25%' }}>
                            <div className="button-container prodView-button-list d-flex">
                                <div className="circle-button" onClick={() => this.handleButtonClick('product')}>
                                    <div className={selectedButton === 'product' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                        <Grid3x3GapFill className="button-icon" />
                                    </div>
                                    <div className="button-text">Product</div>
                                </div>
                                <div className="circle-button" onClick={() => this.handleButtonClick('features')}>
                                    <div className={selectedButton === 'features' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                        <CardList className="button-icon" />
                                    </div>
                                    <div className="button-text">Features</div>
                                </div>
                                <div className="circle-button" onClick={() => this.handleButtonClick('documents')}>
                                    <div className={selectedButton === 'documents' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                        <FileText className="button-icon" />
                                    </div>
                                    <div className="button-text">Documents</div>
                                </div>
                                <div className="circle-button" onClick={() => this.handleButtonClick('gallery')}>
                                    <div className={selectedButton === 'gallery' ? 'icon-wrapper menu-btn-selected' : 'icon-wrapper menu-btn-unselected'}>
                                        <FileText className="button-icon" />
                                    </div>
                                    <div className="button-text">Gallery</div>
                                </div>
                            </div>
                        </div>

                    </Col>
                    {this.state.isLoading ? (
                        // Render a loading indicator while content is being loaded
                        <>
                            <Col sm={6} className="text-center" style={{paddingTop: '10%'}}>
                                <span className="loader"></span>
                            </Col>
                            <Col >
                            </Col>
                        </>
                    ) : (
                        // Render the content once it's loaded
                            this.state.Content
                    )}
                    
                </Row>
                <Modal isOpen={isOpen} toggle={this.closeModal} size="xl">
                    
                    <ModalBody className="modal-body">
                        <div className="image-container-prodView-modal">
                            {selectedImage && (
                                <img
                                    src={this.state.imageDirectory + '/' +selectedImage}
                                    alt="Product"
                                    className="full-screen-image-prodView-modal"
                                />
                            )}
                        </div>
                    </ModalBody>
                </Modal>
            </Container>


        );
    }
}

