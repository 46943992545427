import React, { Component } from 'react';
import HomeImage from '../assets/homeImage.jpg'
import '../css/main.css'
import axios from 'axios'
export class Home extends Component {
  static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            imageDataList: []
        };
    }


    async componentDidMount() {
        this.AuthUser();
        
    }

    async AuthUser() {
        
        await axios.get("/Website/MHEWebsiteLogin", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                this.RunImageCheck();
                console.log(response.data)
            })
            .catch(error => {
                console.error(error);
            });
    }

    async RunImageCheck() {

        await axios.get("/Website/CheckAndCopyBlobStorageToWebsite", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('bearerToken')}`
            }
        })
            .then(response => {
                console.log(response.data)
            })
            .catch(error => {
                console.error(error);
            });
    }

  render() {
      return (
          <div className="fullscreen-image">
              <img src={HomeImage} alt="Home" />
          </div>
    );
  }
}
