import { About } from "./components/About";
import { Products } from "./components/Products";
import { Home } from "./components/Home";
import { ProdView } from "./components/ProdView";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/products',
        element: <Products />
    },
    {
        path: '/ProdView',
        element: <ProdView />
    }
];

export default AppRoutes;
