import React, { Component } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Input } from 'reactstrap';
import { Search } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../assets/mhe_logo.png'
import IconInput from './Shared/IconInput'

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    render() {
    return (
        <Navbar className="navbar-dark bg-black">
            <NavbarBrand href="/">
                <img src={logo} alt="Logo" className="logo" />
            </NavbarBrand>
            <Nav className="mx-auto nav-links">
                <NavItem>
                    <NavLink href="/" className="nav-link">Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/Products" className="nav-link text-center">Products</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/about" className="nav-link">About Us</NavLink>
                </NavItem>
            </Nav>
            <span style={{ width: '6%' }}></span>
        </Navbar>
    );
  }
}
